//import * as dat from 'lil-gui'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'


const points = [
    {
        position: new THREE.Vector3(-3, 1.5, 0.7),
        element: document.querySelector('.point-0')
    },
    {
        position: new THREE.Vector3(1.45, 1.3, -0.25), // Replace x1, y1, z1 with desired coordinates
        element: document.querySelector('.point-1')
    },
    {
        position: new THREE.Vector3(0.8, 1.2, -1.5), // Replace x2, y2, z2 with desired coordinates
        element: document.querySelector('.point-2')
    },
    {
        position: new THREE.Vector3(0, 1.3, -1.38), // Replace x2, y2, z2 with desired coordinates
        element: document.querySelector('.point-3')
    },

    {
        position: new THREE.Vector3(0.3, 1.5, 2), // Replace x2, y2, z2 with desired coordinates
        element: document.querySelector('.contact-card')
    }
]

// Rotation object
const rotation = {
    value: 0
};


// Canvas
const canvas = document.querySelector('canvas.webgl')




// This would be your new canvas for the scrolling lines texture
const textureCanvas = document.createElement('canvas');
textureCanvas.width = 512;
textureCanvas.height = 512;

const context = textureCanvas.getContext('2d');

const texture = new THREE.CanvasTexture(textureCanvas);
texture.minFilter = THREE.LinearFilter;

// Create material
const material = new THREE.MeshPhongMaterial({ map: texture, transparent: true });

// Adjust the number of lines based on the canvas height and desired line spacing
let lineSpacing = 20;
let numberOfLines = Math.floor(textureCanvas.height / lineSpacing);

// Define the minimum length you want
const minLength = 70;
const maxLength = textureCanvas.width *0.95

// Create an array to store line lengths
let lineLengths = Array(numberOfLines).fill().map(() => minLength + Math.random() * (maxLength - minLength));

// Animation variables
let offset = 0;
const speed = 2;  // Adjust speed to your liking

// In your render loop
function animate() {
    // Clear canvas
    context.clearRect(0, 0, textureCanvas.width, textureCanvas.height);

    // Fill canvas with white
    context.fillStyle = 'rgba(255, 255, 255, 0)'; 
    context.fillRect(0, 0, textureCanvas.width, textureCanvas.height);

 // Draw lines
for (let i = 0; i < numberOfLines; i++) {
    context.beginPath();
    context.moveTo(textureCanvas.width - lineLengths[i], (i * lineSpacing + offset) % textureCanvas.height);
    context.lineTo(textureCanvas.width, (i * lineSpacing + offset) % textureCanvas.height);
    context.lineWidth = 12; // Thicker lines
    context.strokeStyle = 'black';
    context.stroke();
}

    // Update offset
    offset += speed;

    // Update texture
    texture.needsUpdate = true;

    // Continue rendering
    requestAnimationFrame(animate);
}

animate();






// Scene
const scene = new THREE.Scene()

/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader()

// Draco loader
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

/**
 * Textures
 */
const bakedTexture = textureLoader.load('baked004.jpg')
bakedTexture.flipY = false
bakedTexture.encoding = THREE.sRGBEncoding

/**
 * Materials
 */
// Baked material
const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })

const ambientLight = new THREE.AmbientLight(0xffffff, 2); // white light, half intensity
scene.add(ambientLight);

const dirLight = new THREE.DirectionalLight(0xffffff, 1); // white light, full intensity
dirLight.position.set(5, 5, 5); // position the light somewhere in the scene
scene.add(dirLight);

/**
 * Model
 */
gltfLoader.load(
    'montageatelierfinalbake2.glb',
    (gltf) => {
        const object = gltf.scene.getObjectByName("fenetre");
        if (object) {
            const position = new THREE.Vector3();
            object.getWorldPosition(position);
            console.log(position);
        } else {
            console.error('Object not found');
        }
        const bakedMesh = gltf.scene.children.find(child => child.name === 'fondations')
        gltf.scene.traverse((child) => {
            if(child.isMesh) {
                child.material = bakedMaterial;
            }
        });
        bakedMesh.material = bakedMaterial
        scene.add(gltf.scene)
    }
)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100);
camera.position.set(0, 1.05, 0);
scene.add(camera);

// Custom rotation controls
const rotationSpeed = 0.0005;

// Rotation function
const handleRotation = (deltaY) => {
    const deltaRotation = deltaY * rotationSpeed;
    rotation.value = (rotation.value + deltaRotation + 2 * Math.PI) % (2 * Math.PI);
    camera.rotation.y = rotation.value;
    rotationControl.setValue(rotation.value);
};

// Detect touch support
const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;

if (isTouchDevice) {
    let lastTouchY;

    // Touch event for touch devices
    canvas.addEventListener('touchmove', (event) => {
        event.preventDefault();
        const touch = event.touches[0];
        let deltaY;
        if (lastTouchY) {
            deltaY = touch.clientY - lastTouchY;
        }
        lastTouchY = touch.clientY; // Update lastTouchY here
        if (deltaY) {
            handleRotation(deltaY);
        }
    });

    // Reset lastTouchY on touchend
    canvas.addEventListener('touchend', () => {
        lastTouchY = null;
    });
} else {
    // Wheel event for non-touch devices
    canvas.addEventListener('wheel', (event) => {
        handleRotation(event.deltaY);
    });
}

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding

// Handle point hover events
for (const point of points) {
    const { element } = point;

    // Skip the interaction setup if this is the 'contact-card'
    if (element.classList.contains('contact-card')) {
        continue;
    }

    element.addEventListener('mouseover', () => {
        element.classList.add('hover');
    });

    element.addEventListener('mouseout', () => {
        element.classList.remove('hover');
    });

    // Also stop propagation on the text so it doesn't trigger mouseout on the parent.
    const textElement = element.querySelector('.text');
    textElement.addEventListener('mouseover', (event) => {
        event.stopPropagation();
    });
}

// Create geometry
const geometry = new THREE.PlaneGeometry(0.5, 0.32);

// Create mesh
const mesh = new THREE.Mesh(geometry, material);

// Adjust position
mesh.position.set(-1, 1.095, -0.2); // replace x, y, z with desired coordinates

material.side = THREE.DoubleSide

// Adjust rotation (in radians)
mesh.rotation.x = 0; // rotates 90 degrees around the X-axis
mesh.rotation.y = Math.PI / 2; // no rotation around the Y-axis
mesh.rotation.z = 0; // no rotation around the Z-axis

// Add mesh to scene
scene.add(mesh);


/**
 * Animate
 */
let then = 0;
const fps = 30;
const interval = 1000 / fps;
const clock = new THREE.Clock()
const frustum = new THREE.Frustum();

const tick = (now) => {
    requestAnimationFrame(tick);
    const delta = now - then;

    if (delta > interval) {
        const elapsedTime = clock.getElapsedTime()

        // Render
        renderer.render(scene, camera)

        // Update points
        for (const point of points) {
            const screenPosition = point.position.clone();
            screenPosition.project(camera);

            // Transform the normalized position into CSS coordinates
            const x = (screenPosition.x * 0.5 + 0.5) * sizes.width;
            const y = (screenPosition.y * -0.5 + 0.5) * sizes.height;

            // Set the position of the HTML point
            point.element.style.left = `${x}px`;
            point.element.style.top = `${y}px`;

            // Get frustum
            frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse));

            // Check whether the point is within the frustum
            if (frustum.containsPoint(point.position)) {
                // The point is within the frustum
                // Make the HTML point visible
                point.element.style.display = 'block';
            } else {
                // The point is not within the frustum
                // Hide the HTML point
                point.element.style.display = 'none';
            }
        }
        then = now - (delta % interval);
    }
}
requestAnimationFrame(tick);
// Get the Spotify player element
var spotifyPlayerContainer = document.getElementById('spotify-player-container');

document.querySelectorAll('.label').forEach((button) => {
    button.addEventListener('click', (event) => {
        event.stopPropagation();
        const popupSelector = button.dataset.popupTarget;
        const popupContent = document.querySelector(popupSelector);
        popupContent.classList.add('show');
        // If this is the music popup, append the player to the popup
        if (popupContent.classList.contains('music-popup')) {
            popupContent.appendChild(spotifyPlayerContainer);
            spotifyPlayerContainer.style.display = 'block';
            spotifyPlayerContainer.classList.remove('minimized');
        }
    });
});

document.querySelectorAll('.popup-content').forEach((popup) => {
    popup.addEventListener('click', (event) => {
        // This is to prevent the event from bubbling up to the document
        event.stopPropagation();
    });
});

let closeButtons = document.querySelectorAll('.close-button');
let musicPopup = document.querySelector('.music-popup');

for(let i = 0; i < closeButtons.length; i++){
    closeButtons[i].addEventListener('click', function(e){
        if (this.parentNode.classList.contains('music-popup')){
            this.parentNode.classList.remove('show');
            // On closing the music popup, append the player to the body
            document.body.appendChild(spotifyPlayerContainer);
            spotifyPlayerContainer.style.display = 'block';
            spotifyPlayerContainer.classList.add('minimized');
        } else {
            this.parentNode.classList.remove('show');
        }
    });
}